import { useCallback, useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { FiltrosRepostajes } from "./FiltrosRepostajes";
import { FiltrosRepostajesMobile } from "./FiltrosRepostajesMobile";
import { useTranslation } from "react-i18next";
import { TablaRepostajes } from "./TablaRepostajes";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { changeFlota } from "../../store/auth";

import "./repostajes.css";
import "../ui/filtros/selects.css";

const URL_GET_REPOSTAJES = "repostajes";

export const RepostajesScreen = () => {
  const [repostajes, setRepostajes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingVehicles, setLoadingVehicles] = useState(true);
  const { token, nombre, apellido, flotaActiva, flotas } = useSelector(
    (state) => state.auth
  );
  const [resultadosBusqueda, setResultadosBusqueda] = useState(repostajes);
  const [resultadosFiltrados, setResultadosFiltrados] = useState(repostajes);
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [busqueda, setBusqueda] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [ordered, setOrdered] = useState(false);
  const [resultTable, setResultTable] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeFlota(flotaActiva, token));
  }, []);

  const [vehiculos, setVehiculos] = useState([
    t("repostajes.filtros.vehiculos.turismo"),
    t("repostajes.filtros.vehiculos.moto"),
    t("repostajes.filtros.vehiculos.camion"),
  ]);
  const [matriculasVehiculos, setMatriculasVehiculos] = useState([]);

  useEffect(() => {}, [resultadosFiltrados]);

  useEffect(() => {
    setResultTable("filtrados");
  }, [filtered]);

  const orderData = (asc, index) => () => {
    let data;

    data =
      resultTable === "busqueda"
        ? resultadosBusqueda
        : resultTable === "filtrados"
        ? resultadosFiltrados
        : repostajes;

    switch (index) {
      case 0:
        index = "fecha";
        break;
      case 1:
        index = "matricula";
        break;
      case 2:
        index = "tipo_vehiculo";
        break;
      case 3:
        index = "combustible";
        break;
      case 4:
        index = "litros";
        break;
      case 5:
        index = "importe_cob";
        break;
      case 6:
        index = "tipo_pago";
        break;
      case 7:
        index = "nombre";
        break;
      case 8:
        index = "km";
        break;
      default:
        break;
    }

    if (asc) {
      data.sort((a, b) => {
        if (index === "fecha") {
          const dateA = new Date(
            a[index].split(" ")[0].split("-").reverse().join("-") +
              " " +
              a[index].split(" ")[1]
          );
          const dateB = new Date(
            b[index].split(" ")[0].split("-").reverse().join("-") +
              " " +
              b[index].split(" ")[1]
          );
          return dateA.getTime() - dateB.getTime();
        } else {
          if (a[index] < b[index]) {
            return -1;
          }
          if (a[index] > b[index]) {
            return 1;
          }
          return 0;
        }
      });
    } else {
      data.sort((a, b) => {
        if (index === "fecha") {
          const dateA = new Date(
            a[index].split(" ")[0].split("-").reverse().join("-") +
              " " +
              a[index].split(" ")[1]
          );
          const dateB = new Date(
            b[index].split(" ")[0].split("-").reverse().join("-") +
              " " +
              b[index].split(" ")[1]
          );
          return dateB.getTime() - dateA.getTime();
        } else {
          if (a[index] > b[index]) {
            return -1;
          }
          if (a[index] < b[index]) {
            return 1;
          }
          return 0;
        }
      });
    }

    // filtered ? setResultadosBusqueda(data) : setRepostajes(data);
    resultTable === "busqueda"
      ? setResultadosBusqueda(data)
      : resultTable === "filtrados"
      ? setResultadosFiltrados(data)
      : setRepostajes(data);

    setOrdered(true);
  };
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   let value = document.getElementById("buscadorRepostajes").value;
  //   setBusqueda(value);
  //   filtrar(value);
  // };

  useEffect(() => {
    if (terminoBusqueda != "") {
      buscar(terminoBusqueda);
      setBusqueda(true);
    } else {
      setBusqueda(false);
      setResultTable("filtrados");
    }
  }, [terminoBusqueda]);

  const buscar = (terminoBusqueda) => {
    let origenDatos = filtered ? resultadosFiltrados : repostajes;
    let result = origenDatos.filter((elemento) => {
      if (
        elemento.fecha
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase().replace(/\//g, "-")) ||
        elemento.litros
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.matricula
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.nombre
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.importe_cob
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.tipo_pago
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        (elemento.tipo_pago === "" &&
          t("repostajes.table.pago-petroprix")
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())) ||
        elemento.direccion
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.km
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase())
      ) {
        return elemento;
      }
    });
    setResultadosBusqueda(result);
    setResultTable("busqueda");
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row
            className={`page-header pb-4 ${flotas === "1" ? "has-flotas" : ""}`}
          >
            <Col xs={9} sm={9} lg={{ span: 1, order: 1 }}>
              <h1>{t("repostajes.h1")}</h1>
            </Col>
            {isMobile ? (
              <Col
                xs={3}
                sm={3}
                lg={{ span: 1, order: 2 }}
                className="d-block d-md-none"
              >
                <FiltrosRepostajesMobile
                  url={URL_GET_REPOSTAJES}
                  token={token}
                  matriculas={matriculasVehiculos}
                  vehiculos={vehiculos}
                  setRepostajes={setRepostajes}
                  setLoading={setLoading}
                  loadingVehicles={loadingVehicles}
                  setLoadingVehicles={setLoadingVehicles}
                  setMatriculas={setMatriculasVehiculos}
                  setTerminoBusqueda={setTerminoBusqueda}
                  setFiltered={setFiltered}
                  setResultadosFiltrados={setResultadosFiltrados}
                />
              </Col>
            ) : (
              <Col
                className="filter-select-filters repostajes d-none d-lg-block step8"
                lg={{ span: 8, order: 2 }}
              >
                <FiltrosRepostajes
                  url={URL_GET_REPOSTAJES}
                  token={token}
                  matriculas={matriculasVehiculos}
                  vehiculos={vehiculos}
                  setRepostajes={setRepostajes}
                  setLoading={setLoading}
                  loadingVehicles={loadingVehicles}
                  setLoadingVehicles={setLoadingVehicles}
                  setMatriculas={setMatriculasVehiculos}
                  setTerminoBusqueda={setTerminoBusqueda}
                  setFiltered={setFiltered}
                  setResultadosFiltrados={setResultadosFiltrados}
                />
              </Col>
            )}
            <Col lg={{ span: 3, order: 3 }}>
              <Form
                className="search"
                autoComplete="off"
                id="formBuscadorRepostajes"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <InputGroup className="mb-2">
                  <InputGroup.Text>
                    <span className="material-icons-outlined">search</span>
                  </InputGroup.Text>
                  <FormControl
                    type="text"
                    placeholder={t("facturas.header.buscador")}
                    className="no-border-left"
                    id="buscadorRepostajes"
                    name="searchText"
                    value={terminoBusqueda}
                    onChange={(e) => {
                      setTerminoBusqueda(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
        <Container className="mt-2 step9">
          <Row className="content-section content-has-flotas">
            {!loading ? (
              <>
                {(resultTable === "busqueda"
                  ? resultadosBusqueda
                  : resultadosFiltrados
                ).length > 0 ? (
                  // (filtered ? resultadosFiltrados : repostajes).length > 0 && (
                  <>
                    <TablaRepostajes
                      // data={filtered ? resultadosFiltrados : repostajes}
                      data={
                        resultTable === "busqueda"
                          ? resultadosBusqueda
                          : resultadosFiltrados
                      }
                      token={token}
                      orderData={orderData}
                      ordered={ordered}
                      setOrdered={setOrdered}
                    />
                    <Row>
                      <Col
                        className="d-none d-lg-block"
                        lg={{ span: 2, offset: 10 }}
                      ></Col>
                    </Row>
                  </>
                ) : (
                  <p className="text-center">
                    {t("repostajes.table.no-results")}
                  </p>
                )}
              </>
            ) : (
              <LoaderPetroprix />
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
