import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Pagination } from "../ui/Pagination";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download_icon from "../../assets/images/download-icon.svg";
import quit_icon from "../../assets/images/quit-icon.svg";
import {
  faFileExcel,
  faFileCsv,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { utils, writeFileXLSX } from "xlsx";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { ModalSolicitarFacturas } from "../facturas/ModalSolicitarFacturas";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { downloadPdf } from "../../helpers/downloadPdf";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_POST_DOWNLOAD_TICKET = "repostajes/ticket";

export const TablaRepostajes = ({
  data,
  token,
  orderData,
  ordered,
  setOrdered,
}) => {
  const { flotaActiva } = useSelector((state) => state.auth);
  const [downloadTickets, setDownloadTickets] = useState(1);
  const [allFacturas, setAllFacturas] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [modalFacturasShow, setModalFacturasShow] = useState(false);
  const [facturasSeleccionadas, setFacturasSeleccionadas] = useState([]);
  const dispatch = useDispatch();

  const nPages = Math.ceil(data.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setOrdered(false);
  }, [ordered]);

  const exportarCSV = () => {
    const csvData = [];
    csvData.push(
      Object.keys(data[0]).map((k) => {
        if (k === "id") return "";
        if (k === "km" && flotaActiva?.km === 0) return "";
        return k;
      })
    );
    data.forEach((row) => {
      csvData.push(
        Object.values(row).map((v) => {
          if (v === row.id) return "";
          if (v === row.km && flotaActiva?.km === 0) return "";
          return v;
        })
      );
    });

    const csvContent = "data:text/csv;charset=utf-8," + csvData.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "repostajes.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const exportarPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title =
      "Listado de repostajes realizados con los filtros seleccionados";

    let headers;
    let data_export;

    if (flotaActiva?.km === 1) {
      headers = [
        [
          "Fecha",
          "Matricula",
          "Km",
          "Vehículo",
          "Combustible",
          "Litros",
          "Precio",
          "Método de pago",
          "Gasolinera",
        ],
      ];

      data_export = data.map((repostaje) => [
        repostaje.fecha,
        repostaje.matricula !== null ? repostaje.matricula : "-",
        repostaje.km,
        repostaje.tipo_vehiculo === "0"
          ? "Turismo"
          : repostaje.tipo_vehiculo === "1"
          ? "Motocicleta"
          : repostaje.tipo_vehiculo === "2"
          ? "Furgoneta"
          : "-",
        repostaje.combustible,
        repostaje.litros,
        repostaje.importe_cob,
        repostaje.tipo_pago === ""
          ? t("repostajes.table.pago-petroprix")
          : repostaje.tipo_pago,
        repostaje.nombre + "\n" + repostaje.direccion + " " + repostaje.cp,
      ]);
    } else {
      headers = [
        [
          "Fecha",
          "Matricula",
          "Vehículo",
          "Combustible",
          "Litros",
          "Precio",
          "Método de pago",
          "Gasolinera",
        ],
      ];

      data_export = data.map((repostaje) => [
        repostaje.fecha,
        repostaje.matricula !== null ? repostaje.matricula : "-",
        repostaje.tipo_vehiculo === "0"
          ? "Turismo"
          : repostaje.tipo_vehiculo === "1"
          ? "Motocicleta"
          : repostaje.tipo_vehiculo === "2"
          ? "Furgoneta"
          : "-",
        repostaje.combustible,
        repostaje.litros,
        repostaje.importe_cob,
        repostaje.tipo_pago === ""
          ? t("repostajes.table.pago-petroprix")
          : repostaje.tipo_pago,
        repostaje.nombre + "\n" + repostaje.direccion + " " + repostaje.cp,
      ]);
    }

    let content = {
      startY: 70,
      head: headers,
      body: data_export,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const exportarXLSX = () => {
    let headers;
    let data_export;

    if (flotaActiva?.km === 1) {
      headers = [
        [
          "Fecha",
          "Matricula",
          "Km",
          "Vehículo",
          "Combustible",
          "Litros",
          "Precio",
          "Método de pago",
          "Gasolinera",
        ],
      ];

      data_export = data.map((repostaje) => [
        repostaje.fecha,
        repostaje.matricula !== null ? repostaje.matricula : "-",
        repostaje.km,
        repostaje.tipo_vehiculo === "0"
          ? "Turismo"
          : repostaje.tipo_vehiculo === "1"
          ? "Motocicleta"
          : repostaje.tipo_vehiculo === "2"
          ? "Furgoneta"
          : "-",
        repostaje.combustible,
        repostaje.litros,

        repostaje.importe_cob,

        repostaje.tipo_pago === ""
          ? t("repostajes.table.pago-petroprix")
          : repostaje.tipo_pago,

        repostaje.nombre + "\n" + repostaje.direccion + " " + repostaje.cp,
      ]);
    } else {
      headers = [
        [
          "Fecha",
          "Matricula",
          "Vehículo",
          "Combustible",
          "Litros",
          "Precio",
          "Método de pago",
          "Gasolinera",
        ],
      ];

      data_export = data.map((repostaje) => [
        repostaje.fecha,
        repostaje.matricula !== null ? repostaje.matricula : "-",
        repostaje.tipo_vehiculo === "0"
          ? "Turismo"
          : repostaje.tipo_vehiculo === "1"
          ? "Motocicleta"
          : repostaje.tipo_vehiculo === "2"
          ? "Furgoneta"
          : "-",
        repostaje.combustible,
        repostaje.litros,

        repostaje.importe_cob,

        repostaje.tipo_pago === ""
          ? t("repostajes.table.pago-petroprix")
          : repostaje.tipo_pago,

        repostaje.nombre + "\n" + repostaje.direccion + " " + repostaje.cp,
      ]);
    }

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(headers.concat(data_export));
    utils.book_append_sheet(wb, ws, "Repostajes");
    writeFileXLSX(wb, "repostajes.xlsx");
  };

  const downloadTicket = async (cmpid, idCcp = null) => {
    let data = {
      cmpid: cmpid,
      idCcp: idCcp,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(URL_POST_DOWNLOAD_TICKET, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data.ticket;
          downloadPdf(data, cmpid, "ticket");
        } else if (response?.data?.code === 404) {
          toast(t("facturas.form.table.download-error"), {
            icon: <LogoPetroprix />,
          });
          console.warn(response.data.message);
        } else {
          toast(t("facturas.form.table.download-error"), {
            icon: <LogoPetroprix />,
          });
          console.warn(response.data.message);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      toast(t("facturas.form.table.download-error"), {
        icon: <LogoPetroprix />,
      });
      console.error(error);
    }
  };

  const changeSelectFacturas = (e) => {
    setDownloadTickets(!downloadTickets);
  };

  const solicitarFacturas = async () => {
    let facturas = [];

    const repostajes = document.querySelectorAll("div.repostaje");
    repostajes.forEach((repostaje) => {
      const checkbox = repostaje.querySelector("input[type=checkbox]");
      if (checkbox.checked) {
        const id = checkbox.value;
        facturas.push(id);
      }
    });

    setFacturasSeleccionadas(facturas);
    setModalFacturasShow(true);
  };

  const selectAllFacturas = (e) => {
    if (e.target.checked) {
      setAllFacturas(true);
      const repostajes = document.querySelectorAll("div.repostaje");
      repostajes.forEach((repostaje) => {
        const checkbox = repostaje.querySelector("input[type=checkbox]");
        checkbox.checked = true;
      });
    } else {
      setAllFacturas(false);
      const repostajes = document.querySelectorAll("div.repostaje");
      repostajes.forEach((repostaje) => {
        const checkbox = repostaje.querySelector("input[type=checkbox]");
        checkbox.checked = false;
      });
    }
  };

  useEffect(() => {
    if (currentPage > nPages) {
      setCurrentPage(1);
    }
  }, [currentRecords]);

  return (
    <>
      {/** Seccion para mostrar los botones para exportar la tabla*/}
      <div className="d-flex justify-content-end mb-3">
        <OverlayTrigger
          key={1}
          placement="top"
          overlay={
            <Tooltip id={"tooltip1"}>{t("repostajes.export.csv")}</Tooltip>
          }
        >
          <button
            className="btn btn-outline-success export-button"
            onClick={exportarCSV}
          >
            <FontAwesomeIcon icon={faFileCsv} />
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          key={2}
          placement="top"
          overlay={
            <Tooltip id={"tooltip2"}>{t("repostajes.export.xls")}</Tooltip>
          }
        >
          <button
            className="btn btn-outline-success export-button"
            onClick={exportarXLSX}
          >
            <FontAwesomeIcon icon={faFileExcel}>
              <FontAwesomeIcon icon={faFileExcel} />
            </FontAwesomeIcon>
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          key={3}
          placement="top"
          overlay={
            <Tooltip id={"tooltip3"}>{t("repostajes.export.pdf")}</Tooltip>
          }
        >
          <button
            className="btn btn-outline-danger export-button"
            onClick={exportarPDF}
          >
            <FontAwesomeIcon icon={faFilePdf} />
          </button>
        </OverlayTrigger>
      </div>

      <Table className="repostajes" borderless={true} hover={false}>
        <thead>
          <tr>
            <th style={{ width: "8%" }}>
              {t("repostajes.table.fecha")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 0)} />
                <AiFillCaretUp onClick={orderData(true, 0)} />
              </span>
            </th>
            <th style={{ width: "9%" }}>
              {t("repostajes.table.matricula")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 1)} />
                <AiFillCaretUp onClick={orderData(true, 1)} />
              </span>
            </th>
            {flotaActiva?.km === 1 && (
              <th style={{ width: "9%" }}>
                {t("repostajes.table.km")}
                <span className="order-table-icons float-end">
                  <AiFillCaretDown onClick={orderData(false, 8)} />
                  <AiFillCaretUp onClick={orderData(true, 8)} />
                </span>
              </th>
            )}
            <th className="d-none d-lg-block" style={{ width: "8%" }}>
              {t("repostajes.table.vehiculo")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 2)} />
                <AiFillCaretUp onClick={orderData(true, 2)} />
              </span>
            </th>
            <th style={{ width: "10%" }}>
              {t("repostajes.table.combustible")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 3)} />
                <AiFillCaretUp onClick={orderData(true, 3)} />
              </span>
            </th>
            <th style={{ width: "8%" }}>
              {t("repostajes.table.litros")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 4)} />
                <AiFillCaretUp onClick={orderData(true, 4)} />
              </span>
            </th>
            <th className="d-none d-lg-block" style={{ width: "8%" }}>
              {t("repostajes.table.precio")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 5)} />
                <AiFillCaretUp onClick={orderData(true, 5)} />
              </span>
            </th>
            <th style={{ width: "12%" }}>
              {t("repostajes.table.tipo-pago")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 6)} />
                <AiFillCaretUp onClick={orderData(true, 6)} />
              </span>
            </th>
            <th className="d-none d-lg-block" style={{ width: "16%" }}>
              {t("repostajes.table.gasolinera")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 7)} />
                <AiFillCaretUp onClick={orderData(true, 7)} />
              </span>
            </th>
            <th
              style={{ width: "12%" }}
              className="repostajes-download-title step10"
              id="repostajes-download-title"
            >
              {downloadTickets ? (
                <span
                  onClick={() => {
                    changeSelectFacturas();
                  }}
                >
                  {t("repostajes.table.select-factura")}
                </span>
              ) : (
                <>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={solicitarFacturas}
                  >
                    {t("repostajes.table.solicitar-factura")}
                  </Button>
                  <Form.Check
                    type="checkbox"
                    className="repostajes-table-select"
                    onClick={(e) => {
                      selectAllFacturas(e);
                    }}
                  />
                  <span
                    onClick={() => {
                      changeSelectFacturas();
                    }}
                  >
                    <img
                      src={quit_icon}
                      alt="Cerrar"
                      className="repostajes-solicitud-x-btn"
                    />
                  </span>
                </>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((repostaje) => (
            <tr key={repostaje.id}>
              <td className="repostajes-fecha">
                <span className="repostajes-fecha-dia">
                  {repostaje.fecha.split(" ")[0].replaceAll("-", "/")}
                </span>
                <span className="repostajes-fecha-hora">
                  {repostaje.fecha.split(" ")[1].slice(0, 5)}
                </span>
              </td>
              <td className="repostajes-matricula">
                {repostaje.matricula !== null ? repostaje.matricula : "-"}
              </td>
              {flotaActiva?.km === 1 && (
                <td className="repostajes-km">{repostaje.km}</td>
              )}
              <td className="repostajes-vehiculo d-none d-lg-block">
                {repostaje.tipo_vehiculo === "0" ? (
                  "Turismo"
                ) : repostaje.tipo_vehiculo === "1" ? (
                  "Motocicleta"
                ) : repostaje.tipo_vehiculo === "2" ? (
                  "Camión"
                ) : (
                  <OverlayTrigger
                    key={repostaje.id}
                    placement="top"
                    overlay={
                      <Tooltip id={"tooltip3"}>
                        {t("repostajes.table.vehiculo.no-registrado")}
                      </Tooltip>
                    }
                  >
                    <span>-</span>
                  </OverlayTrigger>
                )}
              </td>
              <td className="repostajes-combustible">
                {repostaje.combustible === "Diesel" ? (
                  <Alert variant="warning">{repostaje.combustible}</Alert>
                ) : (
                  <Alert variant="success">{repostaje.combustible}</Alert>
                )}
              </td>
              <td className="repostajes-libros">{repostaje.litros} L</td>
              <td className="repostajes-precio d-none d-lg-block">
                {repostaje.importe_cob} €
              </td>
              <td className="repostajes-tipo-pago">
                {repostaje.tipo_pago === "Tarjeta" ? (
                  <Alert variant="outline-blue">{repostaje.tipo_pago}</Alert>
                ) : repostaje.tipo_pago === "Efectivo" ? (
                  <Alert variant="outline-success">{repostaje.tipo_pago}</Alert>
                ) : (
                  <Alert variant="outline-primary">
                    {t("repostajes.table.pago-petroprix")}
                  </Alert>
                )}
              </td>
              <td className="repostajes-gasolinera d-none d-lg-block">
                <span className="repostajes-gasolinera-nombre">
                  {repostaje.nombre}
                </span>
                <span className="repostajes-gasolinera-direccion">
                  {repostaje.direccion}, {repostaje.cp}
                </span>
              </td>
              <td className="repostajes-download">
                {downloadTickets ? (
                  <OverlayTrigger
                    key={repostaje.id}
                    placement="top"
                    overlay={
                      <Tooltip id={"tooltip3"}>
                        {t("repostajes.download.ticket")}
                      </Tooltip>
                    }
                  >
                    <span
                      onClick={() => {
                        downloadTicket(repostaje.id, flotaActiva?.id_cuenta);
                      }}
                    >
                      <img
                        src={download_icon}
                        alt={t("repostajes.download.ticket")}
                        className="repostaje-download-ticket-btn"
                      />
                    </span>
                  </OverlayTrigger>
                ) : allFacturas ? (
                  <Form.Check
                    type="checkbox"
                    className="repostajes-table-select repostaje"
                    key={repostaje.id}
                    value={repostaje.id}
                    defaultChecked={allFacturas}
                  />
                ) : (
                  <Form.Check
                    type="checkbox"
                    className="repostajes-table-select repostaje"
                    key={repostaje.id}
                    value={repostaje.id}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={data.length}
        setRecordsPerPage={setRecordsPerPage}
      />
      <ModalSolicitarFacturas
        show={modalFacturasShow}
        onHide={() => setModalFacturasShow(false)}
        facturas={facturasSeleccionadas}
      />
    </>
  );
};
